import './style/index.less';
import dailySentence from './controller/dailySentence.js';

// 文档就绪
$(function () {
  let mySwiper = new window.Swiper('.swiper-container', {
    direction: 'vertical', // 垂直滚动
    mousewheel: true, // 开启鼠标滚轮控制Swiper切换
    simulateTouch: false, // 设置为false后鼠标拖动无效，移动端仍可正常滑动。
    // scrollbar: { // 滚动条
    //   el: '.swiper-scrollbar',
    // },
    on: {
      init: function() {
        window.swiperAnimateCache(this); //隐藏动画元素
        window.swiperAnimate(this); //初始化完成开始动画
        setTimeout(function() {
          $('.page-1 .mask').addClass('blur');
        }, 500);
      },
      slideChangeTransitionEnd: function() {
        // 如果切换到page1则blur背景,this.activeIndex为0时是page1
        if (this.activeIndex) {
          $('.page-1 .mask').removeClass('blur');
        } else {
          $('.page-1 .mask').addClass('blur');
        }
        window.swiperAnimate(this); //每个slide切换结束时也运行当前slide动画

        let dailySentenceHeight = $('#dailySentence').height(); // 'dailySentence'的高度
        if (this.activeIndex == 2) {
          // 为page3时
          $('#dailySentence').animate({
            bottom: 0
          }); // 显示“每日一句”
        } else {
          $('#dailySentence').animate({
            bottom: -dailySentenceHeight
          });
        }
      }
    }
  });
  mySwiper.updateSize();

  // 每日一句
  dailySentence();
});