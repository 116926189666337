import axios from 'axios';

// 获取数据
function getData() {
  return new Promise((resolve, reject) => {
    axios.get('https://api.gushi.ci/all').then(data => {
      resolve(data.data);
    }).catch(err => {
      reject(err);
    });
  });

}

function dailySentence() {
  getData().then(data => {
    let text = `“${data.content}” ${data.author}《${data.origin}》`;
    $('#dailySentenceText').text(text);
    let height = $('#dailySentence').height();
    $('#dailySentence').css({bottom: -height});
  }).catch(err => {
    // eslint-disable-next-line no-console
    console.log(err);
  });

  // 绑定刷新按钮
  let $refreshBtn = $('#dailySentence .refresh-btn');
  $refreshBtn.on('click', function () {
    $refreshBtn.addClass('rotate');
    getData().then(data => {
      $refreshBtn.removeClass('rotate');
      let height = $('#dailySentence').height();
      $('#dailySentence').animate({
        bottom: -height
      }, function () {
        let text = `“${data.content}” ${data.author}《${data.origin}》`;
        $('#dailySentenceText').text(text);
        $('#dailySentence').animate({
          bottom: 0
        });
      });
    }).catch(err => {
      $refreshBtn.removeClass('rotate');
      // eslint-disable-next-line no-console
      console.log(err);
    });
  });
}

export default dailySentence;